// Name 
// Date
// Email 1
// Email 2


// Buy
// Completed 
// Land count

// Value


// Order ID
// Payment Reference id
import Select from 'react-select'
import { Button,  Toast, ToastBody, ToastHeader } from 'reactstrap'
import ContactFormDemo from '../component/DesignSystem/Core/Common/ContactFormDemo/ContactFormDemo'
import LoadingBlock from '../component/DesignSystem/Core/Common/LoadingBlock/LoadingBlock'
import SearchInput from '../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { useState } from 'react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../component/DesignSystem/Core/Common/QButton/QButton'
import { TRInfo } from '../component/misc/smallComponents'
import { SvgIcons } from '../component/misc/SvgIcons'


interface Order {
  name: string
  sentEmail: string
  receivedEmail: string
  quantum2OrderId: string
  stripePaymentId: string
  type: "buy" | "sell" | "transfer"
  currency: string
  number: number
  value: number
  approvedBy: string
  paymentMethod: string
  note?: string
  status: string
  date: string
}

const orders: Order[] = [
  {
    name: "John Doe",
    sentEmail: "john@example.com",
    receivedEmail: "receiver@example.com",
    quantum2OrderId: "Q2-001",
    stripePaymentId: "pi_1234567890",
    type: "buy",
    currency: "USD",
    number: 100,
    value: 5000,
    approvedBy: "Admin1",
    paymentMethod: "Credit Card",
    note: "First time buyer",
    status: "Completed",
    date: "2023-06-01",
  },
  {
    name: "Jane Smith",
    sentEmail: "jane@example.com",
    receivedEmail: "seller@example.com",
    quantum2OrderId: "Q2-002",
    stripePaymentId: "pi_0987654321",
    type: "sell",
    currency: "EUR",
    number: 50,
    value: 2500,
    approvedBy: "Admin2",
    paymentMethod: "Bank Transfer",
    status: "Pending",
    date: "2023-06-02",
  },
  {
    name: "Bob Johnson",
    sentEmail: "bob@example.com",
    receivedEmail: "alice@example.com",
    quantum2OrderId: "Q2-003",
    stripePaymentId: "pi_1357924680",
    type: "transfer",
    currency: "Land",
    number: 1,
    value: 10000,
    approvedBy: "Admin3",
    paymentMethod: "Internal Transfer",
    note: "Gift transfer",
    status: "Processing",
    date: "2023-06-03",
  },
]
export default function QDesignSystem() {
  const [value, setValue] = useState('Search')
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div className="content-wrapper pp-test">
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row tr-filter-wrapper">


              <div className="col-md-2">
                <Select
                  placeholder="Type"
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]} />
              </div>
              <div className="col-md-2">
                <Select
                  placeholder="Currency"
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]} />
              </div>
              <div className="col-md-2">
                <Select
                  placeholder="Payment Method"
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]} />
              </div>
              <div className="col-md-2">
                <Select
                  placeholder="Status"
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]} />
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      placeholder="Search By"
                      options={[
                        { value: 'Name', label: 'Search By - Name' },
                        { value: 'Email', label: 'Search By - Email' },
                      ]} />
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input placeholder="Search" type="text" className="h-auto form-control" value="" />
                      <div className="input-group-append">
                        <button type="button" className="btn btn-primary"><span> Search</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div className="col-md-12 payment-history">

            {orders.map((order, index) => (
              <div className="tr-card">
                <div className="info-icon-wrap">

                  <div className="ic-label-wrap">
                    <div className="ic-label withbg">
                      {SvgIcons.MoneyCash}
                      <span>Payment Sent</span>
                    </div>
                  </div>
                  <div className="ic-label-wrap">
                    <div className="ic-label withbg">
                      {SvgIcons.CheckCircle}
                      <span>Land (200) - $900 </span>
                    </div>
                  </div>
                  <div className="ic-label-wrap">
                    <div className="ic-label">
                      {SvgIcons.ArrowDown}
                      <span>Buy</span>
                    </div>
                  </div>
                </div>
                <div className="info-text">
                  <div className="info-col">
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"Astrid Vaiksaar"}
                        label={"Name"}
                      />
                    </div>
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"21 March 2021"}
                        label={"Date"}
                      />
                    </div>

                  </div>
                  <div className="info-col">
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"astrid.vaiksaar@harjuelu.ee"}
                        label={"Sent Email"}
                      />
                    </div>
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"astrid.vaiksaar@harjuelu.ee"}
                        label={"Received Email"}
                      />
                    </div>

                  </div>
                  <div className="info-col">
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"Bank"}
                        label={"Payment Method"}
                      />
                    </div>
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"Abhishek Ray"}
                        label={"Approved by"}
                      />
                    </div>
                  </div>
                  <div className="info-col">
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"7cef910c-4b47-4695-8dc2-8085f4"}
                        label={"Quantum2 Order Id"}
                      />
                    </div>
                    <div className="tr-info-wrap">
                      <TRInfo
                        value={"7cef910c-4b47-4695-8dc2-8085f4"}
                        label={"Stripe Payment Id/Bank Refrence"}
                      />
                    </div>
                  </div>



                </div>


              </div>
            ))}
            {orders.map((order, index) => (
              <div className="payment-card card rounded-lg border w-100 shadow-sm mb-4">
                <div className="card-body p-4">
                  <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-4">
                    <div className="d-flex gap-2 mt-2 mt-sm-0">
                      <div className="badge text-white bg-danger px-3 py-1 mr-2">Buy</div>
                      <div className="badge text-white bg-success px-3 py-1">Completed</div>
                      <div className="badge text-white bg-danger px-3 py-1 ml-2">#3 Land</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="h5 fw-bold">John Doe</h3>
                      <div className="mb-3">
                        <div className="d-flex align-items-center mb-2">


                          <span className="fw-semibold">Sent:</span>
                          <span className="ms-2">john@example.com</span>
                        </div>
                        <div className="d-flex align-items-center">


                          <span className="fw-semibold">Received:</span>
                          <span className="ms-2">receiver@example.com</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Value:</span>
                          <span><i className="fa fa-euro mr-6"></i> 3.59</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Order ID:</span>
                          <span>cef910c-4b47-4695-8dc2-8085f4ab2a78</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Date:</span>
                          <span>6/1/2023</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Amount:</span>
                          <span className="fw-bold">100 USD ($5,000)</span>
                        </div>


                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="mb-4">
                        <div className="d-block justify-content-between">
                          <p className="fw-semibold">Stripe Payment Id/Bank Refrence:</p>
                          <p>pi_3Q3bS9RwgUtQV6uV0P4PFfOO</p>
                        </div>


                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Amount:</span>
                          <span className="fw-bold">100 USD ($5,000)</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">Payment Method:</span>
                          <span>Credit Card</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <hr />
                  <p>Approved by: Astrid</p>


                  <p className="" >
                    A simple primary Note
                  </p>


                </div>
              </div>
            ))}



          </div>
        </div>
      </div>
    </div>
  )
  return (
    <div className="content-wrapper ">
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="all-forms design-system-block">
              <Button color="danger" onClick={toggle}>
                Click Me
              </Button>
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                scrollable
                onClosed={() => { }}
              >
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </ModalBody>
                <ModalFooter>
                  <QButton round color="primary" onClick={toggle}>
                    Do Something
                  </QButton>{' '}
                  <QButton round color="secondary" onClick={toggle}>
                    Cancel
                  </QButton>
                </ModalFooter>
              </Modal>
            </div>
            <div className="all-forms design-system-block">
              <div>
                <div className="mb-4" style={{ maxWidth: 400 }}>
                  <SearchInput
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    ButtonText="Search"
                    placeholder={'Search'}
                    // loading={true}
                    onClick={() => alert('click')}
                  />
                </div>
                <Button color="primary" onClick={function noRefCheck() { }}>
                  Verify Me
                </Button>
                <br />
                <br />
                <Toast isOpen={false}>
                  <ToastHeader toggle={function noRefCheck() { }}>
                    Verify Me
                  </ToastHeader>
                  <ToastBody>
                    Please enter all the required data for verification
                  </ToastBody>
                </Toast>
              </div>
            </div>
            <div className="all-forms design-system-block">
              <ContactFormDemo />
            </div>
            <div className="all-button design-system-block">
              <h4>Buttons</h4>
              <QButton>QButton</QButton>{' '}
              <QButton loading={true}> Loading</QButton>{' '}
              <QButton color="primary">primary</QButton>{' '}
              <QButton color="secondary">secondary</QButton>{' '}
              <QButton color="success">success</QButton>{' '}
              <QButton color="info">info</QButton>{' '}
              <QButton color="warning">warning</QButton>{' '}
              <QButton color="danger">danger</QButton>{' '}
              <QButton color="primary" round>
                Round
              </QButton>{' '}
              <QButton color="link">link</QButton>
              <QButton color="primary" className="mt-2" round fullWidth>
                full Width
              </QButton>{' '}
            </div>
            <div className="all-forms design-system-block">
              <h4>Loading indicator</h4>
              <LoadingBlock />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
