import React, {  useEffect, useState } from 'react'

import {  useNavigate } from 'react-router-dom'


import { FormGroup, Input, Label } from 'reactstrap'

import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import * as yup from 'yup'



import { toast } from 'react-toastify'
import {
  
  testAxios,
} from '../../utils/constants'



import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormErrorMessage } from '../FormErrorMessage'

import { stringToSlug } from '../../pages/addNews'
import { init_revalidate_next_pages } from '../../utils/common'

export interface NewsCategoryFormValues {
  id?: string;
  slug: string;
  name: string;
}

export const CategorySchema = yup.object().shape({
  name: yup.string().required('Category name is required'),
  slug: yup.string().required('slug is required'),

})

const NewsCategoryAdd: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)


  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const [doc_modal, setdoc_Modal] = useState(false)
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<NewsCategoryFormValues>({
    resolver: yupResolver(CategorySchema),
  })

  const name = watch('name')
  useEffect(() => {
    if (name) {
      const slug = stringToSlug(name)
      setValue('slug', slug)
    }
  }, [name, setValue])

  console.log('errors:', errors)
  const onSubmit: SubmitHandler<NewsCategoryFormValues> = async (data) => {
    const url = `${process.env.REACT_APP_SERVER_URI}/news/news-category`

    try {
      setLoading(true)

      const response = await testAxios.post(url, {
        ...data,
      })
      toast.success(response.data.message, {
        position: 'bottom-center',
      })
      setLoading(false)
      init_revalidate_next_pages()
      navigate('/news/viewNewsCategory/')
    } catch (error: any) {
      console.log('error:', error)
      setLoading(false)
      toast.error(error.response.data.message, { position: 'bottom-center' })
    }
  }



  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>



          <div className="form-group">
            <Label>
              <b>Enter Category Name</b>
            </Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Category Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group">
            <Label>
              <b>Enter slug</b>
            </Label>
            <Controller
              name="slug"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Category slug"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.slug && (
              <FormErrorMessage>{errors.slug.message}</FormErrorMessage>
            )}
          </div>



          <div className="text-right ml-auto" style={{ maxWidth: '250px' }}>
            <QButton
              round
              onClick={() => navigate('/news/viewNewsCategory/')}
              className="mr-3"
            >
              <b>Go Back</b>
            </QButton>
            <QButton round loading={loading} type="submit" >
              <b>Submit</b>
            </QButton>
          </div>
        </form>
      </div>
    
    
    </div>
  )
}

export default NewsCategoryAdd
