export const SvgIcons = {
  CheckCircle: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_71_58)">
      <path d="M4 8L6.05 9.64C6.10506 9.68534 6.16952 9.71784 6.23872 9.73514C6.30791 9.75244 6.38009 9.7541 6.45 9.74C6.52058 9.72676 6.58749 9.69847 6.64616 9.65706C6.70483 9.61564 6.75389 9.56207 6.79 9.5L10 4" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_71_58">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
  ,
  Check: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 8.54999L3.23 12.06C3.32212 12.1797 3.44016 12.2769 3.57525 12.3445C3.71034 12.412 3.85898 12.4481 4.01 12.45C4.15859 12.4517 4.3057 12.4203 4.44063 12.358C4.57555 12.2957 4.6949 12.2042 4.79 12.09L13.5 1.54999" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  ,
  ArrowDown: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_71_63)">
      <path d="M4 7L7 10.5L10 7" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 10.5V3.5" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_71_63">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
  ,
  ArrowUp: "",
  MoneyCash: <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1545_11408)">
      <path d="M7.5 4.5V3" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 8.5C6 9.25 6.67 9.5 7.5 9.5C8.33 9.5 9 9.5 9 8.5C9 7 6 7 6 5.5C6 4.5 6.67 4.5 7.5 4.5C8.33 4.5 9 4.88 9 5.5" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.5 9.5V11" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.5 13.5C11.0899 13.5 14 10.5899 14 7C14 3.41015 11.0899 0.5 7.5 0.5C3.91015 0.5 1 3.41015 1 7C1 10.5899 3.91015 13.5 7.5 13.5Z" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1545_11408">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>


  ,
  // MoneyCash: <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <g clip-path="url(#clip0_1545_11393)">
  //     <path d="M10.5 1.75H2C1.44772 1.75 1 2.19772 1 2.75V8.75C1 9.30228 1.44772 9.75 2 9.75H10.5C11.0523 9.75 11.5 9.30228 11.5 8.75V2.75C11.5 2.19772 11.0523 1.75 10.5 1.75Z" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
  //     <path d="M6.25 7.25C7.07843 7.25 7.75 6.57843 7.75 5.75C7.75 4.92157 7.07843 4.25 6.25 4.25C5.42157 4.25 4.75 4.92157 4.75 5.75C4.75 6.57843 5.42157 7.25 6.25 7.25Z" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
  //     <path d="M4 12.25H13C13.2652 12.25 13.5196 12.1446 13.7071 11.9571C13.8946 11.7696 14 11.5152 14 11.25V6.25" stroke="#000001" stroke-linecap="round" stroke-linejoin="round" />
  //   </g>
  //   <defs>
  //     <clipPath id="clip0_1545_11393">
  //       <rect width="14" height="14" fill="white" transform="translate(0.5)" />
  //     </clipPath>
  //   </defs>
  // </svg>
  // ,
}