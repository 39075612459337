import { revalidateNextJsPages } from "../api"
import { urlBasedOnEnv } from "./constants"

export const init_revalidate_next_pages = async () => {
  try {

    //@ts-ignore
    let res = await revalidateNextJsPages(`${urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]}/api/revalidate?secret=${process.env.REACT_APP_ISR_TOKEN_NEXT}`)
    console.log('res:', res)
  } catch (e) {
    console.log('e:', e)


  }
}

export const camelCaseToSpaced = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}