import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'reactstrap'

import { Link } from 'react-router-dom'
import { useAppContext } from '../../../context/AppContext'
import { AdminRoles, GlobalMenuItem, NavMenuItem } from '../../../utils/types'
import { ControlledCollapseMenuItemNEW } from '../../misc/smallComponents'
import { postLogout } from '../../../api'
export const AuthMenuItems: GlobalMenuItem[] = [
    {
      parentMenuTitle: 'User Management',
      id: 'user-management',
      menuList: [
        {
          menuTitle: 'Approve Users',
          path: '/user/approve',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'View Approved Users',
          path: '/user/approved-list',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'Delete User Account',
          path: '/user/remove',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'Edit/View User Account',
          path: '/user/manage',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'Enable/Disable User',
          path: '/user/enable-disable',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'View Subscribers',
          path: '/view-subscribers',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'Approve Community Agents',
          path: '/user/approve-community-agents',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'View Approved Community Agents',
          path: '/user/view-approved-agent',
          roles: [AdminRoles.UserAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Business Management',
      id: 'business-management',
      menuList: [
        {
          menuTitle: 'Approve Businesses',
          path: '/business/approve',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'View Approved Businesses',
          path: '/business/approved-list',
          roles: [AdminRoles.UserAdmin],
        },
        {
          menuTitle: 'Remove Business Account',
          path: '/business/remove',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'Edit/View Business Account',
          path: '/business/manage',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'Enable/Disable Business',
          path: '/business/enable-disable',
          roles: [AdminRoles.UserAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Admin Management',
      id: 'admin-management',
      menuList: [
        {
          menuTitle: 'Assign/Remove Admin role',
          path: '/assignremoveadminrole',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'View Super Admin',
          path: '/viewSuperAdmin',
          roles: [AdminRoles.SuperAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Order Management',
      id: 'order-management',
      menuList: [
        {
          menuTitle: 'Enter/View AUD in Bank',
          path: '/viewAudInBank',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
        {
          menuTitle: 'Stripe Purchase Orders',
          path: '/payoutuserpurchase',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
        {
          menuTitle: 'Bank Purchase Orders',
          path: '/bankuserpurchase',
          roles: [AdminRoles.BankAdmin],
        },
        {
          menuTitle: 'Other Purchase Orders',
          path: '/otherpurchase',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
        {
          menuTitle: 'Sell Orders',
          path: '/usersellorder',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
        {
          menuTitle: 'Approved/Completed',
          path: '/approvecompleted',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Transactions',
      id: 'transactions',
      menuList: [
        {
          menuTitle: 'View All Transactions',
          path: '/admin-view-transaction',
          roles: [AdminRoles.BankAdmin, AdminRoles.FinanceAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Land Management',
      id: 'land-management',
      menuList: [
        {
          menuTitle: 'Add New Land',
          path: '/addNewLand',
          roles: [AdminRoles.LandAdmin],
        },
        {
          menuTitle: 'View Land Details',
          path: '/viewLand',
          roles: [AdminRoles.LandAdmin],
        },
        {
          menuTitle: 'Update Land Details',
          path: '/editLand',
          roles: [AdminRoles.SuperAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'News Management',
      id: 'news-management',
      menuList: [
        {
          menuTitle: 'Add News',
          path: '/news/create',
          roles: [AdminRoles.NewsAdmin],
        },
        {
          menuTitle: 'View/ Edit/ Delete News',
          path: '/news/operation',
          roles: [AdminRoles.NewsAdmin],
        },
        {
          menuTitle: 'Add News Category',
          path: '/news/addNewsCategory',
          roles: [AdminRoles.NewsAdmin],
        },
        {
          menuTitle: 'View News Category',
          path: '/news/viewNewsCategory',
          roles: [AdminRoles.NewsAdmin],
        },
      ],
    },
  
    {
      parentMenuTitle: 'Information',
      id: 'information',
      menuList: [
        {
          menuTitle: 'Add Land Project',
          path: '/addLandProject',
          roles: [AdminRoles.VotingAdmin],
        },
        {
          menuTitle: 'View Land Projects',
          path: '/viewLandProjects',
          roles: [AdminRoles.VotingAdmin],
        },
        {
          menuTitle: 'Add Q2 Development',
          path: '/addProject',
          roles: [AdminRoles.VotingAdmin],
        },
        {
          menuTitle: 'View Q2 Developments',
          path: '/viewProjects',
          roles: [AdminRoles.VotingAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Governance',
      id: 'governance',
      menuList: [
        {
          menuTitle: 'Add New Question',
          path: '/addQuestion',
          roles: [AdminRoles.VotingAdmin],
        },
        {
          menuTitle: 'View Questions',
          path: '/viewQuestion',
          roles: [AdminRoles.VotingAdmin],
        },
        {
          menuTitle: 'Community Ideas',
          path: '/community-ideas',
          roles: [AdminRoles.VotingAdmin],
        },
      ],
    },
    {
      parentMenuTitle: 'Settings',
      id: 'settings',
      menuList: [
        {
          menuTitle: 'Fees Management',
          path: '/setting/feemanagement',
          roles: [AdminRoles.SuperAdmin],
        },
        {
          menuTitle: 'Reference Code Management',
          path: '/setting/reference-code-management',
          roles: [AdminRoles.SuperAdmin],
        },
      ],
    },
  ]

export default function Header() {
    // const isAuth = true;

    const { toggleSideMenu, isSideMenuOpen, setIsSideMenuOpen, user } =
        useAppContext()

    const [childCollapse, setchildCollapse] = useState({})
    const [collapseID, setcollapseID] = useState<string>('')

    const handleLogOut = () => {
        postLogout()
            .then(() => {
                window.location.reload()
                // navigate("/");
            })
            .catch((error) => console.log(error))
    }
    const toggleNestedCollapse = (id: string) => {
        console.log('id:', id)
        setchildCollapse((prev: any) => ({
            ...prev,
            [id]: prev[id] !== undefined ? !prev[id] : true,
        }))
    }

    const linkClickAction = () => {
        setIsSideMenuOpen(false)
        // setchildCollapse((prev: any) => ({
        //     ...prev,

        // }));
    }

    const menuRef = useRef<HTMLDivElement | null>(null)
    const menuTriggerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node) &&
                event.target !== menuTriggerRef.current &&
                !menuTriggerRef.current?.contains(event.target as Node)
            ) {
                setIsSideMenuOpen(false)
            }
        }

        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [isSideMenuOpen])
    if (!user) {
        return null
    }
    let userRoles: AdminRoles[] = []

    if (user.roles && user) {
        userRoles = user.roles
    }

    const isSuperAdmin = user.isSuperAdmin ? true : false

    return (
        <div className="navigation-wrapper">
            <header className="navigation-primary d-flex flex-wrap align-items-center justify-content-between py-3">
                <div className="brand-logo ">
                    <Link
                        onClick={linkClickAction}
                        to="/"
                        className="d-inline-flex link-body-emphasis text-decoration-none"
                    >
                        <img src="/assets/images/quantum2-logo.png" alt="" />
                    </Link>
                </div>

                <div className=" text-end right-nav-buttons ">
                    <Link to={'/dashboard'} color="link" className="btn btn-link">
                        Dashboard
                    </Link>
                    <button onClick={handleLogOut} color="link" className="btn btn-link">
                        Logout
                    </button>

                    <div
                        className={`nav-toggle isAuth`}
                        onClick={toggleSideMenu}
                        ref={menuTriggerRef}
                    >
                        <div className={`nav-icon3 ${isSideMenuOpen ? 'open' : ''}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </header>
            <Collapse
                isOpen={isSideMenuOpen}
                className="primary-authenticated-menu-all"
            >
                <div className="menu-collapse-wrapper" ref={menuRef}>
                    {/* <div className="primary-auth-desktop-menu container">



                        <div className=""


                        >



                            <div className="row mt-5">

                                <div className="col-md-3">
                                    <MenuItem
                                        index={0}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />

                                    <MenuItem
                                        index={1}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                </div>
                                <div className="col-md-3">
                                    <MenuItem
                                        index={2}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                    <MenuItem
                                        index={3}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />




                                </div>

                                <div className="col-md-3">
                                    <MenuItem
                                        index={4}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                    <MenuItem
                                        index={5}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                </div>
                                <div className="col-md-3">
                                    <MenuItem
                                        index={6}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                    <MenuItem
                                        index={7}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />

                                    <MenuItem
                                        index={8}
                                        AuthMenuItems={AuthMenuItems}
                                        userRoles={userRoles}
                                        isSuperAdmin={isSuperAdmin}
                                        linkClickAction={linkClickAction}
                                    />


                                </div>

                            </div>
                        </div>
                    </div> */}
                    <ul className="primary-auth-mobile-menu">
                        <div className="">
                            {AuthMenuItems.map((menu, index) => {
                                const hasRequiredRole = menu.menuList.some((mi: any) =>
                                    mi.roles.some((role: AdminRoles) => userRoles.includes(role)),
                                );

                                // Render only if the user is a super admin or has the required role
                                if (isSuperAdmin || hasRequiredRole) {
                                    return (
                                        <ControlledCollapseMenuItemNEW
                                            index={index}
                                            AuthMenuItems={AuthMenuItems}
                                            userRoles={userRoles}
                                            isSuperAdmin={isSuperAdmin}
                                            linkClickAction={linkClickAction}
                                            isSideMenuOpen={isSideMenuOpen}
                                            collapseID={collapseID}
                                            setcollapseID={setcollapseID}
                                        />
                                    )
                                }
                                return null;
                            }
                            )}

                            {/* {AuthMenuItems.map((menu) => (
                                <ControlledCollapseMenuItem
                                    menuTitle={menu.parentMenuTitle}
                                    id={menu.parentMenuTitle}
                                    childCollapse={childCollapse}
                                    toggleNestedCollapse={toggleNestedCollapse}
                                    linkClickAction={linkClickAction}
                                    linkList={menu.menuList}
                                    isSideMenuOpen={isSideMenuOpen}
                                />
                            ))} */}
                        </div>
                    </ul>
                </div>
            </Collapse>
        </div>
    )
}

interface MenuItemProps {
    index: number
    AuthMenuItems: any[]
    userRoles: AdminRoles[]
    isSuperAdmin: boolean
    linkClickAction: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({
    index,
    AuthMenuItems,
    userRoles,
    isSuperAdmin,
    linkClickAction,
}) => {
    // Check if the index exists within AuthMenuItems
    const menu = AuthMenuItems[index]
    if (!menu) return null

    // Check if the user has the required role
    const hasRequiredRole = menu.menuList.some((mi: any) =>
        mi.roles.some((role: AdminRoles) => userRoles.includes(role)),
    )

    // Render only if the user is a super admin or has the required role
    if (isSuperAdmin || hasRequiredRole) {
        return (
            <div className="menu-column" key={menu.parentMenuTitle}>
                <h5>{menu.parentMenuTitle}</h5>
                <ul>
                    {menu.menuList.map((mi: NavMenuItem) =>
                        isSuperAdmin ||
                            mi.roles.some((role) => userRoles.includes(role)) ? (
                            <li className="single-item" key={mi.menuTitle}>
                                <Link
                                    onClick={linkClickAction}
                                    className="link-item"
                                    to={mi.path}
                                >
                                    {mi.menuTitle}
                                </Link>
                            </li>
                        ) : null,
                    )}
                </ul>
            </div>
        )
    }

    return null
}
