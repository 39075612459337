import { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment, { Moment } from "moment";
let i;
const years: React.ReactElement[] = [];
for (i = moment().year(); i >= moment().year() - 5; i--) {
    years.push(
        <option value={i} key={`year-${i}`}>
            {i}
        </option>
    );
}
const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
}: {
    month: moment.Moment;
    onMonthSelect: (month: moment.Moment, value: string) => void;
    onYearSelect: (month: moment.Moment, value: string) => void;
}) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
            <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
                {moment.months().map((label, value) => (
                    <option value={value}>{label}</option>
                ))}
            </select>
        </div>
        <div>
            <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {/* {yrList.map((y,i)=> <option value={moment().year() - i+1}>{moment().year() - i+1}</option>)} */}
                {/* {yrList.map((y,i)=> <option value={moment().year() - i}>{moment().year() - i+1}</option>)} */}
                {/* <option value={moment().year()}>{moment().year()}</option> */}
                {/* <option value={moment().year() + 1}>Next year</option> */}
                {years}
            </select>
        </div>
    </div>
);
function SingleDatePickerComponent({
    date,
    setDate,
    id,
    placeholder,
    isBefore = true,
    ...props
}: {
    id: string;
    placeholder: string;
    isBefore?: boolean;
    date: moment.Moment | null;
    setDate: (d: moment.Moment) => void;
}) {
    // const [date, setDate] = useState(null);
    const [focused, setFocused] = useState<boolean>(false);

    const handleDateChange = (newDate: moment.Moment | null) => {
        setDate(newDate ? newDate : moment());
    };
    const isFutureDateDisabled = (day: Moment) => {
        // Disable future dates
        return day.isBefore(); // default is current date
    };
    return (
        <div className="SingleDatePickerComponent">
            <SingleDatePicker
                date={date} // momentPropTypes.momentObj or null
                onDateChange={handleDateChange} // PropTypes.func.isRequired
                focused={focused} // PropTypes.bool
                onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                id={id} // PropTypes.string.isRequired,
                // minimumNights={0} // Minimum nights for range
                // isOutsideRange={() => false} // Function to disable future dates
                numberOfMonths={1}
                isOutsideRange={isBefore ? isFutureDateDisabled : d => false }
                // renderMonthElement={renderMonthElement}
                readOnly={true}
                placeholder={placeholder}
                displayFormat="DD/MM/YYYY"
                // withPortal
                {...props}
            />
        </div>
    );
}

export default SingleDatePickerComponent;
