
import { createContext, useState, useContext, ReactElement, useEffect } from "react";


interface AppContextType {
    isAuthenticated: boolean;
    loading: boolean;
    user: any;
    login: () => void;
    logout: () => void;
    isSideMenuOpen: boolean;
    toggleSideMenu: () => void;
    setIsSideMenuOpen: (val: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = (): AppContextType => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppContextProvider");
    }
    return context;
};

const defaultValues: AppContextType = {
    isAuthenticated: false,
    loading: true,
    user: null,
    login: () => { },
    logout: () => { },
    isSideMenuOpen: false,
    toggleSideMenu: () => { },
    setIsSideMenuOpen: () => { },
};

export const AppContextProvider = ({ children, user }: { children: ReactElement, user: string | null }) => {
    // const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(defaultValues.isAuthenticated);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(defaultValues.isSideMenuOpen);

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         setLoading(true);
    //         try {
    //             const user = await getUser();
    //             setUser(user);
    //             setLoading(false);
    //         } catch (e) {
    //             setLoading(false);
    //         }
    //     };

    //     fetchUser();
    // }, []);

    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    const toggleSideMenu = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const contextValue: AppContextType = {
        isAuthenticated,
        login,
        logout,
        isSideMenuOpen,
        loading,
        user,
        toggleSideMenu,
        setIsSideMenuOpen,
    };

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
